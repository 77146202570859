import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { Typography, Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import XIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import { PATHS } from "util/appConstants";
import { selectTour, selectTourStatus, getTour } from "redux/slices/tourSlice";
import Navbar from "components/Navbar";
import CustomersNavbar from "components/Masterbar/CustomersBar";
import DarkLayout from "components/Shared/DarkLayout";
import { selectUser } from "redux/slices/userSlice";
import { sortOrder } from "util/customers/deliveryTimings";
import { getSupplierLocations, selectSupplierLocations } from "redux/slices/supplierLocationsSlice";

const useStyles = makeStyles({
  _heading: {
    color: "#F5F5F5",
    font: "normal normal normal 28px/40px Questrial",
  },
  _edit: {
    color: "#6F9CEB",
    width: "22px",
    height: "22px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.3)",
    },
    "&:hover + span": {
      display: "block",
    },
  },
  _editbox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  _infoheading: {
    color: "#F5F5F5",
    font: "normal normal medium 22px/32px Roboto",
    marginTop: "44px",
  },
  _head: {
    color: "#F5F5F5",
    opacity: "0.6",
    font: "normal normal normal 12px/24px Roboto",
    marginRight: "32px",
  },
  _basicdetailhead: {
    display: "flex",
    marginTop: "41px",
  },
  _basicdetail: {
    display: "flex",
  },
  _detail: {
    color: "#F5F5F5",
    marginRight: "32px",
    font: "normal normal normal 18px/24px Roboto",
  },
  _name: {
    // width: '228px',
    letterSpacing: "0px",
  },
  _width11: {
    // width: '11px'
  },
  _margintop80: {
    marginTop: "80px",
  },
  _adressbox: {
    marginTop: "24px",
  },
  _buttonbox: {
    display: "flex",
    margin: "0 16px",
  },
  _edittext: {
    width: "24px",
    height: "16px",
    color: "#6F9CEB",
    font: "normal normal normal 14px / 20px Roboto",
    padding: "4px 8px",
    display: "none",
    position: "absolute",
    marginLeft: "20px",
    transition: "all 0.3s ease-in-out",
  },
});

const TourDetail = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loading = useSelector(selectTourStatus);
  const tour = useSelector(selectTour);
  const user = useSelector(selectUser);
  const supplierLocations = useSelector(selectSupplierLocations);

  useEffect(() => {
    if (id && !loading) {
      dispatch(getTour(id));
    }
    dispatch(getSupplierLocations());
  }, [dispatch, id]);

  const editTourHandler = () => {
    history.push(PATHS.tours.edit.replace(":id", id));
  };

  return (
    <>
      <Navbar />
      <CustomersNavbar />
      <DarkLayout doublebar loading={loading || !tour}>
        <div className={classes._editbox}>
          <Typography className={classes._heading} variant="h4">
            {tour?.name}
          </Typography>
          {(user?.permissions?.toursCreate || user?.permissions?.tours?.create) && (
            <Box className={classes._buttonbox} component="div">
              <EditIcon onClick={editTourHandler} className={classes._edit} />
              <Typography component="span" className={clsx(classes._edittext, "edittag")}>
                {t("Edit")}
              </Typography>
            </Box>
          )}
        </div>
        <Typography className={classes._infoheading} variant="h5">
          {t("Basic Data")}
        </Typography>
        <div className={classes._basicdetailhead}>
          <div>
            <Typography className={clsx(classes._head, classes._width11)} variant="h6">
              t{"ID"}
            </Typography>
            <Typography className={clsx(classes._detail, classes._width11)} variant="h6">
              {tour?.id}
            </Typography>
          </div>
          <div>
            <Typography className={clsx(classes._head, classes._name)} variant="h6">
              {t("Name")}
            </Typography>
            <Typography className={clsx(classes._name, classes._detail)} variant="h6">
              {tour?.name}
            </Typography>
          </div>
          <div>
            <Typography className={classes._head} variant="h6">
              {t("Description")}
            </Typography>
            <Typography className={classes._detail} variant="h6">
              {tour?.description}
            </Typography>
          </div>
          <div>
            <Typography className={classes._head} variant="h6">
              {t("Active")}
            </Typography>
            <Typography className={classes._detail} variant="h6">
              {tour?.active ? <CheckIcon /> : <XIcon />}
            </Typography>
          </div>
          <div>
            <Typography className={classes._head} variant="h6">
              {t("Default tour")}
            </Typography>
            <Typography className={classes._detail} variant="h6">
              {tour?.is_default ? <CheckIcon /> : <XIcon />}
            </Typography>
          </div>
          <div>
            <Typography className={classes._head} variant="h6">
              {t("Third party")}
            </Typography>
            <Typography className={classes._detail} variant="h6">
              {tour?.third_party ? <CheckIcon /> : <XIcon />}
            </Typography>
          </div>
        </div>
        <div className={classes._basicdetailhead}>
          {" "}
          <div>
            <Typography className={classes._head} variant="h6">
              {t("Delivery Timings")}
            </Typography>
            <Typography className={classes._detail} variant="h6">
              {tour?.delivery_timings
                ? [...tour.delivery_timings]
                    .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
                    .map((deliveryTiming) => t(deliveryTiming))
                    .join(", ")
                : ""}
            </Typography>
          </div>
        </div>
        <div className={classes._basicdetailhead}>
          <div>
            <Typography className={classes._head} variant="h6">
              {t("Allowed Supplier Locations")}
            </Typography>
            <Typography className={classes._detail} variant="h6">
              {supplierLocations
                ?.filter((location) =>
                  tour?.ToursAlloweds?.some((allowed) => allowed?.supplier_location_id === location.id)
                )
                .map((location) => location.name)
                .join(", ")}
            </Typography>
          </div>
        </div>
        <div className={classes._basicdetailhead}>
          <div>
            <Typography className={classes._head} variant="h6">
              {t("Tour Start Location")}
            </Typography>
            <Typography className={classes._detail} variant="h6">
              {supplierLocations?.find((location) => tour?.supplier_location_id === location.id)?.name}
            </Typography>
          </div>
        </div>
      </DarkLayout>
    </>
  );
};

export default TourDetail;
