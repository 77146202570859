import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import EmptyCircleIcon from "@material-ui/icons/RadioButtonUnchecked";
import FilledCircleIcon from "@material-ui/icons/RadioButtonChecked";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import clsx from "clsx";
import { ORDERS_TABLE_COLUMNS } from "constants/ui-constants";
import {
  getColumns,
  getOrdersActions,
  getLocalization,
} from "util/table-utils";
import { mapTableData } from "util/helpers";
import { PATHS } from "util/appConstants";
import {
  selectOrders,
  selectOrderStatus,
  getOrders,
  deleteOrder,
  selectOrdersTimestamp,
} from "redux/slices/orderSlice";
import { addHandover, selectHandoverStatus } from "redux/slices/handoverSlice";
import { setShowMessage } from "redux/slices/uiSlice";
import { createRoute } from "redux/slices/routeSlice";
import { selectUser } from "redux/slices/userSlice";
import withConfirm from "components/dialogs/delete";
import DarkLayout from "components/Shared/DarkLayout";
import Navbar from "components/Navbar";
import pen from "../../assets/img/pen.svg";
import { Select, Input, DatePicker } from "components/Shared/mui-formik-inputs";
import { formatCustomerLabel } from "util/orders/formatCustomerLabel";
import { sortOrder } from "util/customers/deliveryTimings";

const locales = {
  "en-us": "en",
  en: "en",
  de: "de",
};

const useStyles = makeStyles({
  _1F1F1F: {
    background: "#1F1F1F",
  },
  _525252: {
    background: "#525252",
  },
  _edit: {
    background: "#6F9CEB ",
    borderRadius: "50%",
    padding: "2px",
    width: "13px",
    height: "13px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  _pointer: {
    cursor: "pointer",
  },
  input: {
    "& svg": {
      color: "white !important",
    },
    "& label": {
      color: "white !important",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white !important",
    },

    "& .MuiFilledInput-root": {
      color: "white !important",
      borderRadius: "4px !important",
      border: "1px solid white !important",
    },
  },
  button: {
    color: "white",
  },
});

const tableTitle = "ORDERS";

const OrderList = ({ confirm }) => {
  const tableRef = useRef();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const loading = useSelector(selectOrderStatus);
  const handoverLoading = useSelector(selectHandoverStatus);
  const orders = useSelector(selectOrders);
  const timestamp = useSelector(selectOrdersTimestamp);
  const user = useSelector(selectUser);
  const [jsonData, setjsonData] = useState(orders);
  const [filters, setFilters] = useState({
    departure_date: moment(),
    departure: null,
    tour_number: "",
  });
  const [filteredJsonData, setFilteredJsonData] = useState([]);

  const fetchOrders = async () => {
    await dispatch(getOrders());
  };

  useEffect(() => {
    if (!filters.departure_date && !filters.departure && !filters.tour_number) {
      return setFilteredJsonData(jsonData);
    }

    const filtered = jsonData.reduce((acc, row) => {
      let orders = row.orders;

      if (filters.departure_date) {
        orders = row.orders.filter((order) => {
          const departure_date = moment(
            `${order.departure_date} 12:00:00`,
            "YYYY-MM-DD HH:mm:ss"
          ).utc();

          console.log(
            order.departure_date,
            filters.departure_date.utc(),
            departure_date
          );

          if (moment().isSame(filters.departure_date, "day")) {
            return (
              !order.departure_date ||
              filters.departure_date.utc().isSameOrAfter(departure_date, "day")
            );
          }

          return (
            !order.departure_date ||
            filters.departure_date.utc().isSame(departure_date, "day")
          );
        });
      }

      if (filters.departure) {
        orders = orders.filter(
          (order) => order.departure === filters.departure
        );
      }

      if (filters.tour_number) {
        orders = orders.filter((order) => {
          const searchStr = `${order.Customer?.Tour?.id || ""} ${
            order.Customer?.Tour?.name || ""
          }`.toLowerCase();

          return searchStr.includes(filters.tour_number.toLowerCase().trim());
        });
      }

      if (!orders.length) {
        return acc;
      }

      return acc.concat([
        {
          ...row,
          orders,
        },
      ]);
    }, []);

    setFilteredJsonData(filtered);
  }, [jsonData, filters]);

  useEffect(() => {
    if (!orders.length && !loading && !timestamp) {
      fetchOrders();
    }

    setjsonData(orders);
  }, [orders, loading]);

  useEffect(() => {
    if (timestamp) {
      fetchOrders();
    }
  }, []);

  const changeDepartureDate = (value) => {
    if (value === 1) {
      setFilters((f) => ({ departure_date: f.departure_date.add(1, "day") }));
    } else {
      setFilters((f) => ({
        departure_date: f.departure_date.subtract(1, "day"),
      }));
    }
  };

  const callbackOnDelete = (e, order) => {
    e.stopPropagation();
    confirm(() => dispatch(deleteOrder(order.id)), {
      description: "Are you sure?",
    });
  };

  const actions = getOrdersActions({
    callbackOnDelete: (e, rowData) => callbackOnDelete(e, rowData),
    addHandler: () => addHandler(),
    editHandler: (e, rowData) => editHandler(rowData),
    startTourCheck: () => startTourCheck(),
    startTour: (v = null) => startTour(v),
    createHandover: () => createHandover(),
    t,
    user,
  });

  const addHandler = () => {
    history.push(PATHS.orders.add);
  };
  const editHandler = (rowData) => {
    history.push(PATHS.orders.edit.replace(":id", rowData.id));
  };

  const createHandover = async () => {
    const rows = jsonData.reduce((acc, row) => {
      return acc.concat(row.orders.filter((o) => o.checked));
    }, []);

    if (!rows.length) {
      return;
    }

    await dispatch(addHandover({ order_ids: rows.map((o) => o.id) }, t));
    fetchOrders();
  };

  const checkChangeHandler = (e, clickedRow) => {
    e.stopPropagation();

    const newData = tableRef.current.state.data.map((row) => {
      if (row.id !== clickedRow.id) {
        return {
          ...row,
        };
      }

      return {
        ...row,
        mainCheck: e.target.checked,
        orders: row.orders.map((o) => ({
          ...o,
          checked: e.target.checked,
        })),
      };
    });

    setjsonData(newData);
  };

  const startTourCheck = () => {
    return !!jsonData.find((row) => {
      return row.orders.some((o) => o.checked);
    });
  };

  const startTour = async (type = null) => {
    if (!startTourCheck()) {
      return;
    }

    const rows = jsonData.filter((row) => {
      return row.orders.some((o) => o.checked);
    });

    confirm(
      async () => {
        let i = 0;

        while (i < rows.length) {
          await dispatch(
            createRoute({
              order_ids: rows[i].orders
                .filter((o) => o.checked)
                .map((o) => o.id),
              tour_id: rows[i].Tour.id,
              type,
            })
          );

          i += 1;
        }

        dispatch(
          setShowMessage({
            description: "The routes where created successfully",
            type: "success",
          })
        );

        fetchOrders();
      },
      {
        description: `${t("Start route with the selected orders?")}`,
      }
    );
  };

  const innerChangeHandler = (order) => {
    // when an order is selected we go over the rows
    // each row being a Tour that groups orders
    const newData = tableRef.current.state.data.map((row) => {
      // if the row (Tour) is not the same as the Order's
      // we keep it as is
      if (row.Tour.id !== order.Customer.Tour.id) {
        return {
          ...row,
        };
      }

      // if the row (Tour) is the same as the Order we clicked
      // we go over it's Orders. Changing the status of the clicked, leaving the
      // rest as they were
      return {
        ...row,
        orders: row.orders.map((o) => ({
          ...o,
          checked: o.id === order.id ? !o.checked : o.checked,
        })),
      };
    });

    setjsonData(newData);
  };

  return (
    <>
      <Navbar />
      <DarkLayout loading={loading || handoverLoading}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          locale={locales[i18n.language.toLowerCase()]}
        >
          <Box className={classes.input} display="flex" alignItems="center">
            <Box mr={2}>
              <DatePicker
                onChange={(date) => {
                  if (date && date.isValid()) {
                    setFilters((f) => ({ ...f, departure_date: date }));
                  }

                  if (date === null) {
                    setFilters((f) => ({ ...f, departure_date: null }));
                  }
                }}
                name="departure_date"
                value={filters.departure_date}
                errors={{}}
                label="Departure date"
                clearable
                inputVariant="outlined"
              />
            </Box>
            <Box mr={0.5}>
              <Button
                size="small"
                className={classes.button}
                disabled={!filters.departure_date}
                onClick={() => changeDepartureDate(-1)}
              >
                <ArrowBackIos />
              </Button>
            </Box>
            <Box>
              <Button
                size="small"
                className={classes.button}
                disabled={!filters.departure_date}
                onClick={() => changeDepartureDate(1)}
              >
                <ArrowForwardIos />
              </Button>
            </Box>
            <Box ml={1} width="150px">
              <Select
                label={t("Departure")}
                name="departure"
                onChange={(e) =>
                  setFilters((f) => ({ ...f, departure: e.target.value }))
                }
                onBlur={() => {}}
                value={filters.departure}
                errors={{}}
                options={[{ value: null, label: t("None") }].concat(
                  [
                    ...new Set(
                      orders?.flatMap((tourObj) =>
                        tourObj?.orders?.map((order) => order?.departure)
                      )
                    ),
                  ]
                    .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
                    .map((o) => ({
                      label: t(o),
                      value: o,
                    }))
                )}
              />
            </Box>
            <Box ml={2} width="150px">
              <Input
                label={t("Tour Number")}
                name="tour_number"
                onChange={(e) => {
                  setFilters((f) => ({ ...f, tour_number: e.target.value }));
                }}
                value={filters.tour_number}
              />
            </Box>
          </Box>
        </MuiPickersUtilsProvider>
        <MaterialTable
          tableRef={tableRef}
          data={mapTableData(filteredJsonData)}
          title={t(tableTitle)}
          columns={getColumns(
            ORDERS_TABLE_COLUMNS(
              (e, rowData) => checkChangeHandler(e, rowData),
              t,
              user
            ),
            t
          )}
          actions={actions}
          localization={getLocalization(t)}
          options={{
            pageSize: 50,
            pageSizeOptions: [50, 100],
            detailPanelColumnAlignment: "right",
            paging: false,
            actionsColumnIndex: -1,
            search: false,
            headerStyle: {
              backgroundColor: "#121212",
              color: "white",
              borderBottom: "1px solid #525252",
              font: "normal normal normal 12px/24px Roboto",
              fontWeight: "bold",
            },
            cellStyle: {
              color: "white",
              border: "none",
              font: "normal normal normal 12px/24px Roboto",
            },
            showTitle: false,
            header: false,
            showTextRowsSelected: false,
            showSelectAllCheckbox: false,
            rowStyle: (rowData) => {
              if (rowData.tableData.id % 2 === 0) {
                return { backgroundColor: " #1F1F1F ", height: "71px" };
              } else {
                return { backgroundColor: "#525252", height: "71px" };
              }
            },
          }}
          detailPanel={[
            {
              icon: () => <ExpandMoreIcon />,
              openIcon: () => <ExpandLessIcon />,
              render: (rowData) => {
                return (
                  <>
                    {rowData.orders.map((order, i) => (
                      <Box
                        className={clsx(
                          rowData.tableData.id % 2 === 0
                            ? classes._1F1F1F
                            : classes._525252
                        )}
                        key={i}
                      >
                        <Box display="flex" py={2}>
                          <Box flex={2} pl={8}>
                            {formatCustomerLabel(order.Customer)}
                            <Box fontWeight="bold">
                              {t("Departure date")}:{" "}
                              {order.departure_date
                                ? moment(
                                    `${order.departure_date} 12:00:00`,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                    .utc()
                                    .format("DD.MM.YYYY")
                                : "-"}
                            </Box>
                          </Box>
                          <Box flex={1} ml={5}>
                            {order.express ? "Express" : ""}
                          </Box>
                          <Box flex={1}>{order.number}</Box>
                          <Box flex={1}>
                            {order.packages} {t("Pckg")}
                          </Box>
                          <Box
                            flex={2}
                            style={{
                              textAlign: "right",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box flex={1} display="flex" alignItems="center">
                              <div
                                onClick={() => editHandler(order)}
                                className={clsx(
                                  classes._edit,
                                  classes._pointer
                                )}
                              >
                                <img
                                  alt="icon"
                                  src={pen}
                                  style={{ height: "10px" }}
                                />
                              </div>
                            </Box>
                            {/*{(user?.permissions?.routesCreateForDriver ||*/}
                            {/*  user?.permissions?.routes?.create_driver ||*/}
                            {/*  user?.permissions?.routes?.create_delivery ||*/}
                            {/*  user?.permissions?.routesCreateDeliveryOrder ||*/}
                            {/*  user?.permissions?.handovers?.create) && (*/}
                            <Box flex={1} pr={1.6} textAlign="center">
                              <input
                                onChange={(e) => {
                                  e.stopPropagation();

                                  innerChangeHandler(order);
                                }}
                                className={"radio-checkbox"}
                                id={`panel${order.id}`}
                                type="checkbox"
                                name="field"
                                checked={!!order.checked}
                              />
                              <label
                                className="radio-checkbox-label"
                                htmlFor={`panel${order.id}`}
                              >
                                {order.checked ? (
                                  <FilledCircleIcon />
                                ) : (
                                  <EmptyCircleIcon />
                                )}
                              </label>
                            </Box>
                            {/* )} */}

                            <Box
                              flex={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              <DeleteSharpIcon
                                className={classes._pointer}
                                style={{
                                  marginRight: "20px",
                                  color: "#ADADAD",
                                }}
                                onClick={(e) => {
                                  callbackOnDelete(e, order);
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </>
                );
              },
            },
          ]}
        />
      </DarkLayout>
    </>
  );
};

export default withConfirm(OrderList);
