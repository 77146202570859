import MapIcon from "@material-ui/icons/Map";
import StarRateIcon from "@material-ui/icons/StarRate";
import CallSharpIcon from "@material-ui/icons/CallSharp";
import VpnKeySharpIcon from "@material-ui/icons/VpnKeySharp";
import TooltipBar from "components/Tooltip";
import Tooltip from "@material-ui/core/Tooltip";
import EmptyCircleIcon from "@material-ui/icons/RadioButtonUnchecked";
import FilledCircleIcon from "@material-ui/icons/RadioButtonChecked";
import DocumentIcon from "@material-ui/icons/Description";
import { IconButton } from "@material-ui/core";
import moment from "moment";
import { PATHS } from "util/appConstants";
import { UndoSharp } from "@material-ui/icons";
import { useDispatch } from "react-redux";

export const MASTER_ADMIN_DATA_BAR = {
  name: "Master Data",
  list: [
    {
      name: "Customer Archive",
      path: PATHS.customers.archive,
      permission: (user) => user?.permissions?.customers?.archive,
    },
    {
      name: "Customers",
      path: PATHS.customers.root,
      permission: (user) => user?.permissions?.customers?.list,
    },
    {
      name: "Tours",
      path: PATHS.tours.root,
      permission: (user) => user?.permissions?.tours?.list,
    },
    {
      name: "Zipcodes",
      path: PATHS.zipcodes.root,
      permission: (user) => user?.permissions?.zipcodes?.list,
    },
  ],
};

export const TOUR_DATA_BAR = {
  name: "Routes",
  list: [
    {
      name: "Current routes",
      path: PATHS.routes.current,
      permission: (user) => user?.permissions?.routesList || user?.permissions?.routes?.list,
    },
    {
      name: "Recently finished routes",
      path: PATHS.routes.recent,
      permission: (user) => user?.permissions?.routes?.recently_finished_routes,
    },
    {
      name: "Archive routes",
      path: PATHS.routes.archive,
      permission: (user) => user?.permissions?.routes?.archive,
    },
    {
      name: "Export",
      path: PATHS.routes.export,
      permission: (user) => user?.permissions?.routes?.export,
    },
  ],
};
export const ADMIN_DATA_BAR = {
  name: "Admin",
  list: [
    {
      name: "Supplier Locations",
      path: PATHS.admin.supplierLocations,
      permission: (user) => user?.permissions?.admin?.supplier_locations,
    },
    {
      name: "Account Details",
      path: PATHS.admin.accountDetails,
      permission: (user) => user?.permissions?.admin?.account_details,
    },
  ],
};
export const NAVIGATION_ROUTES = [
  {
    name: "Account Settings",
    path: PATHS.admin.supplierLocations,
    permission: (permissions) => permissions?.admin?.supplier_locations,
  },
  {
    name: "Routes",
    path: PATHS.routes.export,
    permission: (permissions) => permissions?.routes?.export && !permissions?.routes?.list,
  },
  {
    name: "Routes",
    path: PATHS.routes.current,
    permission: (permissions) => permissions?.routesList || permissions?.routes?.list,
  },
  {
    name: "Protocols",
    path: PATHS.handovers.root,
    permission: (permissions) => permissions?.handovers?.list,
  },
  {
    name: "Orders",
    path: PATHS.orders.root,
    permission: (permissions) => permissions?.ordersList || permissions?.orders?.list,
  },
  {
    name: "Past Deliveries",
    path: PATHS.pastdeliveries,
    permission: (permissions) => permissions?.routes?.past_deliveries,
  },
  {
    name: "Delivery History",
    path: PATHS.deliveryhistory,
    permission: (permissions) => permissions?.routes?.delivery_history,
  },
  {
    name: "Master Data",
    path: PATHS.customers.root,
    permission: (permissions) =>
      permissions?.customers?.list || permissions?.tours?.list || permissions?.zipcodes?.list,
  },
  {
    name: "Map",
    path: PATHS.tours.map,
    permission: (permissions) => permissions?.routesMap || permissions?.routes?.map,
  },
  { name: "Settings", path: "/dash2" },
];
export const TOURS_TABLE_COLUMNS = (t) => {
  return [
    { title: "Tour name", field: "name" },
    { title: "Tour ID", field: "id" },
    { title: "Active", field: "active", type: "boolean" },
    { title: "Default tour", field: "is_default", type: "boolean" },
    { title: "Third party", field: "third_party", type: "boolean" },
    {
      title: "Delivery Timings",
      field: "delivery_timings",
      render: (rowData) => {
        return rowData?.delivery_timings
          ? rowData.delivery_timings.map((deliveryTiming) => t(deliveryTiming)).join(", ")
          : "";
      },
    },
  ];
};

export const SUPPLIER_LOCATIONS_TABLE_COLUMNS = (t) => {
  return [
    { title: "Supplier", field: "Supplier.name" },
    { title: "Supplier Location", field: "name" },
    { title: "Street", field: "street" },
    { title: "zipcode", field: "zipcode" },
    { title: "City", field: "city" },
    { title: "Telephone Number", field: "phone" },
    { title: "E-mail", field: "email" },
  ];
};

export const CUSTOMERS_TABLE_COLUMNS = (t) => {
  return [
    { title: "ID", field: "number" },
    {
      title: "Name",
      field: "name",
      customSort: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Street",
      field: "street",
      render: (rowData) => (
        <>
          {rowData.street} {rowData.street_number}
        </>
      ),
    },
    { title: "Zipcode", field: "zipcode" },
    { title: "City", field: "city" },
    { title: "Tour", field: "Tour.name" },
    {
      title: "Express Customer",
      field: "priority",
      render: (rowData) => (rowData.priority ? t("Yes") : t("No")), // Custom render for Priority field
    },
    {
      title: "Active",
      field: "active",
      type: "boolean",
      default: true,
      defaultSort: "desc",
    },
  ];
};
export const CUSTOMERS_TABLE_COLUMNS_ADMIN = (t) => {
  return [
    { title: "ID", field: "number" },
    { title: "Sender", field: "Supplier.name" },
    {
      title: "Name",
      field: "name",
      customSort: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Street",
      field: "street",
      render: (rowData) => (
        <>
          {rowData.street} {rowData.street_number}
        </>
      ),
    },
    { title: "Zipcode", field: "zipcode" },
    { title: "City", field: "city" },
    { title: "Tour", field: "Tour.name" },
    {
      title: "Express Customer",
      field: "priority",
      render: (rowData) => (rowData.priority ? t("Yes") : t("No")), // Custom render for Priority field
    },
    {
      title: "Active",
      field: "active",
      type: "boolean",
      default: true,
      defaultSort: "desc",
    },
  ];
};

export const PAST_DELIVERIES_TABLE_COLUMNS = (t) => {
  return [
    { title: "Customer name", field: "name", defaultSort: "asc" },
    { title: "Customer ID", field: "number" },
    { title: "Zipcode", field: "zipcode" },
    { title: "City", field: "city" },
    {
      title: "Express Customer",
      field: "priority",
      lookup: { true: t("Yes"), false: t("No") },
    },
    // { title: "Tour", field: "Tour.name" },
  ];
};

export const ORDERS_TABLE_COLUMNS = (checkChangeHandler, t, user) => {
  const cols = [
    {
      title: "Order id",
      render: (rowData) => <span style={{ fontSize: "15px", fontWeight: 500 }}>T{rowData.Tour.id}</span>,
    },
    {
      title: "Tour",
      field: "Tour.name",
      render: (rowData) =>
        rowData.Tour.name + (rowData.orders[0].departure ? ` (${t(rowData.orders[0].departure)})` : ""),
    },
    {
      title: "",
      render: (rowData) => (
        <span style={{ color: "#6F9CEB" }}>
          {" "}
          {rowData.orders.length}
          <span style={{ marginLeft: "15px" }}>
            {} {t("New orders")}
          </span>
        </span>
      ),
    },
  ];

  // if (
  //   user?.permissions?.routesCreateForDriver ||
  //   user?.permissions?.routes?.create_driver ||
  //   user?.permissions?.routes?.create_delivery ||
  //   user?.permissions?.routesCreateDeliveryOrder ||
  //   user?.permissions?.handovers?.create ||
  //   user?.email.startsWith('lager@')
  // ) {
  cols.push({
    title: "Id+1",
    render: (rowData) => (
      <div style={{ textAlign: "right", marginRight: "20px", marginTop: "6px" }}>
        <input
          onChange={(e) => checkChangeHandler(e, rowData)}
          className={"radio-checkbox"}
          id={`panel${rowData.id}`}
          type="checkbox"
          name="field"
          checked={rowData.mainCheck}
        />
        <label className="radio-checkbox-label" htmlFor={`panel${rowData.id}`}>
          {rowData.mainCheck ? <FilledCircleIcon /> : <EmptyCircleIcon />}
        </label>
      </div>
    ),
  });
  // }

  return cols;
};

export const CURRENT_TOURS_COLUMNS_CURRENT_TOURS_TAB = (
  tableRef,
  markFavourite,
  redirectView,
  t,
  handleUndo,
  sorting
) => {
  const dispatch = useDispatch();
  return [
    {
      title: "icon",
      render: (rowData) => (
        <div style={{ display: "flex" }}>
          {!!markFavourite && (
            <StarRateIcon
              onClick={(e) => markFavourite(e, rowData)}
              style={{
                color: rowData.is_favourite ? "#6F9CEB" : "#ADADAD",
                cursor: "pointer",
              }}
            />
          )}
          {!!rowData.code && (
            <MapIcon onClick={() => redirectView(null, rowData)} style={{ color: "#ADADAD", cursor: "pointer" }} />
          )}
        </div>
      ),
      customFilterAndSearch: (v, row) => {
        const regex = new RegExp(v, "gi");

        return regex.test(`t.${row.uuid || ""} ${row.uuid || ""} ${row.Tour?.name || ""}`);
      },
    },
    {
      title: "date",
      render: (rowData) => {
        if (!rowData.start_date) {
          if (!rowData?.created_at) {
            return "-";
          }
        }

        return (
          <>
            <span
              style={{
                font: "normal normal bold 18px/24px Roboto",
                color: "#F5F5F5",
              }}
            >
              {rowData.start_date
                ? moment(rowData.start_date).format("DD.MM.YYYY")
                : moment(rowData.created_at).format("DD.MM.YYYY")}{" "}
              {rowData.start_date ? moment(rowData.start_date).format("HH:mm") : ""}
            </span>
          </>
        );
      },
    },
    {
      title: "tour",
      field: "tour",
      render: (rowData) => <span>T.{rowData.uuid}</span>,
    },
    {
      title: "name",
      render: (rowData) => {
        try {
          const gdo =
            rowData.Tour.name +
            (rowData.pathway[0].Orders[0].departure ? ` (${t(rowData.pathway[0].Orders[0].departure)})` : "");

          return gdo;
        } catch (e) {
          console.log(e);
          return rowData.Tour.name;
        }
      },
    },
    {
      title: "progress",
      render: (rowData) => {
        if (rowData.progress === "Complete") {
          return (
            <span
              style={{
                color: "#6F9CEB",
                font: "normal normal normal 18px/24px Roboto",
              }}
            >
              {t(rowData.progress)}
              <small style={{ display: "block" }}>{moment(rowData.end_date).format("DD.MM.YYYY")}</small>
            </span>
          );
        }

        return (
          <span
            style={{
              font: "normal normal normal 18px/24px Roboto",
              color: "#F5F5F5",
            }}
          >
            {t(rowData.progress)}
          </span>
        );
      },
    },
    {
      title: "noOfOrders",
      render: (rowData) =>
        `${rowData.pathway.filter((p) => p.Orders.every((o) => o.delivered_at)).length} / ${rowData.pathway.length}`,
    },
    {
      title: "DriversName",
      field: "driver_name",
      render: (rowData) => (
        <span
          style={{
            font: "normal normal bold 18px/24px Roboto",
            color: "#F5F5F5",
          }}
        >
          {rowData.driver_name || "-"}
        </span>
      ),
    },
    {
      title: "call",
      render: (rowData) => {
        return (
          <>
            {rowData.driver_name !== null ? (
              <Tooltip title={<TooltipBar name={"callicon"} rowData={rowData} />} placement="top" arrow interactive>
                <CallSharpIcon className={"hovericon"} />
              </Tooltip>
            ) : (
              <CallSharpIcon className={"disabled-btn"} disabled={true} />
            )}
          </>
        );
      },
    },
    {
      title: "undo",
      render: (rowData) => {
        return (
          <>
            {rowData.start_date == null ? (
              <IconButton onClick={() => handleUndo(rowData.id)} color="primary" style={{ padding: 0 }}>
                <UndoSharp className={"hovericon"} />
              </IconButton>
            ) : (
              <>
                <UndoSharp className={"disabled-btn"} />
              </>
            )}
          </>
        );
      },
    },
    {
      title: "key",
      render: (rowData) => {
        if (!rowData.code) {
          return (
            <IconButton
              component="a"
              href={`${process.env.REACT_APP_API}routes/document/${rowData.id}/${sorting}?taira=${localStorage.getItem(
                "token"
              )}`}
              target="_blank"
              color="primary"
              style={{ padding: 0 }}
            >
              <DocumentIcon style={{ color: "#6F9CEB" }} />
            </IconButton>
          );
        }

        return (
          <Tooltip title={<TooltipBar name={"vpnicon"} rowData={rowData} />} placement="top" arrow interactive>
            <VpnKeySharpIcon className={"hovericon"} />
          </Tooltip>
        );
      },
    },
  ];
};
export const CURRENT_TOURS_COLUMNS_CURRENT_TOURS_TAB_BOTH_KEY_PDF = (
  tableRef,
  markFavourite,
  redirectView,
  t,
  handleUndo,
  sorting,
  user
) => {
  const dispatch = useDispatch();
  return [
    {
      title: "icon",
      render: (rowData) => (
        <div style={{ display: "flex" }}>
          {!!markFavourite && (
            <StarRateIcon
              onClick={(e) => markFavourite(e, rowData)}
              style={{
                color: rowData.is_favourite ? "#6F9CEB" : "#ADADAD",
                cursor: "pointer",
              }}
            />
          )}
          {!!rowData.code && (
            <MapIcon onClick={() => redirectView(null, rowData)} style={{ color: "#ADADAD", cursor: "pointer" }} />
          )}
        </div>
      ),
      customFilterAndSearch: (v, row) => {
        const regex = new RegExp(v, "gi");

        return regex.test(`t.${row.uuid || ""} ${row.uuid || ""} ${row.Tour?.name || ""}`);
      },
    },
    {
      title: "date",
      render: (rowData) => {
        if (!rowData.start_date) {
          if (!rowData?.created_at) {
            return "-";
          }
        }

        return (
          <>
            <span
              style={{
                font: "normal normal bold 18px/24px Roboto",
                color: "#F5F5F5",
              }}
            >
              {rowData.start_date
                ? moment(rowData.start_date).format("DD.MM.YYYY")
                : moment(rowData.created_at).format("DD.MM.YYYY")}{" "}
              {rowData.start_date ? moment(rowData.start_date).format("HH:mm") : ""}
            </span>
          </>
        );
      },
    },
    {
      title: "tour",
      field: "tour",
      render: (rowData) => <span>T.{rowData.uuid}</span>,
    },
    {
      title: "name",
      render: (rowData) => {
        try {
          const gdo =
            rowData.Tour.name +
            (rowData.pathway[0].Orders[0].departure ? ` (${t(rowData.pathway[0].Orders[0].departure)})` : "");

          return gdo;
        } catch (e) {
          console.log(e);
          return rowData.Tour.name;
        }
      },
    },
    {
      title: "progress",
      render: (rowData) => {
        if (rowData.progress === "Complete") {
          return (
            <span
              style={{
                color: "#6F9CEB",
                font: "normal normal normal 18px/24px Roboto",
              }}
            >
              {t(rowData.progress)}
              <small style={{ display: "block" }}>{moment(rowData.end_date).format("DD.MM.YYYY")}</small>
            </span>
          );
        }

        return (
          <span
            style={{
              font: "normal normal normal 18px/24px Roboto",
              color: "#F5F5F5",
            }}
          >
            {t(rowData.progress)}
          </span>
        );
      },
    },
    {
      title: "noOfOrders",
      render: (rowData) =>
        `${rowData.pathway.filter((p) => p.Orders.every((o) => o.delivered_at)).length} / ${rowData.pathway.length}`,
    },
    {
      title: "DriversName",
      field: "driver_name",
      render: (rowData) => (
        <span
          style={{
            font: "normal normal bold 18px/24px Roboto",
            color: "#F5F5F5",
          }}
        >
          {rowData.driver_name || "-"}
        </span>
      ),
    },
    {
      title: "call",
      render: (rowData) => {
        return (
          <>
            {rowData.driver_name !== null ? (
              <Tooltip title={<TooltipBar name={"callicon"} rowData={rowData} />} placement="top" arrow interactive>
                <CallSharpIcon className={"hovericon"} />
              </Tooltip>
            ) : (
              <CallSharpIcon className={"disabled-btn"} disabled={true} />
            )}
          </>
        );
      },
    },
    !user?.permissions?.routes?.hide_undo && {
      title: "undo",
      render: (rowData) => {
        return (
          <>
            {rowData.start_date == null ? (
              <IconButton onClick={() => handleUndo(rowData.id)} color="primary" style={{ padding: 0 }}>
                <UndoSharp className={"hovericon"} />
              </IconButton>
            ) : (
              <>
                <UndoSharp className={"disabled-btn"} />
              </>
            )}
          </>
        );
      },
    },
    !user?.permissions?.routes?.hide_pdf && {
      title: "pdf",
      render: (rowData) => {
        return (
          <IconButton
            component="a"
            href={`${process.env.REACT_APP_API}routes/document/${rowData.id}/${sorting}?taira=${localStorage.getItem(
              "token"
            )}`}
            target="_blank"
            color="primary"
            style={{ padding: 0 }}
          >
            <DocumentIcon style={{ color: "#6F9CEB" }} />
          </IconButton>
        );
      },
    },
    !user?.permissions?.routes?.hide_key && {
      title: "key",
      render: (rowData) => {
        if (!rowData.code) {
          return (
            <IconButton
              component="a"
              href={`${process.env.REACT_APP_API}routes/document/${rowData.id}/${sorting}?taira=${localStorage.getItem(
                "token"
              )}`}
              target="_blank"
              color="primary"
              style={{ padding: 0 }}
            >
              <DocumentIcon style={{ color: "#6F9CEB" }} />
            </IconButton>
          );
        }

        return (
          <Tooltip title={<TooltipBar name={"vpnicon"} rowData={rowData} />} placement="top" arrow interactive>
            <VpnKeySharpIcon className={"hovericon"} />
          </Tooltip>
        );
      },
    },
  ];
};
export const CURRENT_TOURS_COLUMNS = (tableRef, markFavourite, redirectView, t) => {
  return [
    {
      title: "icon",
      render: (rowData) => (
        <div style={{ display: "flex" }}>
          {!!markFavourite && (
            <StarRateIcon
              onClick={(e) => markFavourite(e, rowData)}
              style={{
                color: rowData.is_favourite ? "#6F9CEB" : "#ADADAD",
                cursor: "pointer",
              }}
            />
          )}
          {!!rowData.code && (
            <MapIcon onClick={() => redirectView(null, rowData)} style={{ color: "#ADADAD", cursor: "pointer" }} />
          )}
        </div>
      ),
      customFilterAndSearch: (v, row) => {
        const regex = new RegExp(v, "gi");

        return regex.test(`t.${row.uuid || ""} ${row.uuid || ""} ${row.Tour?.name || ""}`);
      },
    },
    {
      title: "date",
      render: (rowData) => {
        if (!rowData.start_date) {
          if (!rowData?.created_at) {
            return "-";
          }
        }

        return (
          <>
            <span
              style={{
                font: "normal normal bold 18px/24px Roboto",
                color: "#F5F5F5",
              }}
            >
              {rowData.start_date
                ? moment(rowData.start_date).format("DD.MM.YYYY")
                : moment(rowData.created_at).format("DD.MM.YYYY")}{" "}
              {rowData.start_date ? moment(rowData.start_date).format("HH:mm") : ""}
            </span>
          </>
        );
      },
    },
    {
      title: "tour",
      field: "tour",
      render: (rowData) => <span>T.{rowData.uuid}</span>,
    },
    {
      title: "name",
      render: (rowData) => {
        try {
          const gdo =
            rowData.Tour.name +
            (rowData.pathway[0].Orders[0].departure ? ` (${t(rowData.pathway[0].Orders[0].departure)})` : "");

          return gdo;
        } catch (e) {
          console.log(e);
          return rowData.Tour.name;
        }
      },
    },
    {
      title: "progress",
      render: (rowData) => {
        if (rowData.progress === "Completed") {
          return (
            <span
              style={{
                color: "#6F9CEB",
                font: "normal normal normal 18px/24px Roboto",
              }}
            >
              {t(rowData.progress)}
              <small style={{ display: "block" }}>{moment(rowData.end_date).format("DD.MM.YYYY")}</small>
            </span>
          );
        }

        return (
          <span
            style={{
              font: "normal normal normal 18px/24px Roboto",
              color: "#F5F5F5",
            }}
          >
            {t(rowData.progress)}
          </span>
        );
      },
    },
    {
      title: "noOfOrders",
      render: (rowData) =>
        `${rowData.pathway.filter((p) => p.Orders.every((o) => o.delivered_at)).length} / ${rowData.pathway.length}`,
    },
    {
      title: "DriversName",
      field: "driver_name",
      render: (rowData) => (
        <span
          style={{
            font: "normal normal bold 18px/24px Roboto",
            color: "#F5F5F5",
          }}
        >
          {rowData.driver_name || "-"}
        </span>
      ),
    },
    {
      title: "call",
      render: (rowData) => {
        return (
          <>
            {rowData.driver_name !== null ? (
              <Tooltip title={<TooltipBar name={"callicon"} rowData={rowData} />} placement="top" arrow interactive>
                <CallSharpIcon className={"hovericon"} />
              </Tooltip>
            ) : (
              <CallSharpIcon className={"disabled-btn"} disabled={true} />
            )}
          </>
        );
      },
    },
    {
      title: "key",
      render: (rowData) => {
        if (!rowData.code) {
          return (
            <IconButton
              component="a"
              href={`${process.env.REACT_APP_API}routes/document/${rowData.id}?taira=${localStorage.getItem("token")}`}
              target="_blank"
              color="primary"
              style={{ padding: 0 }}
            >
              <DocumentIcon style={{ color: "#6F9CEB" }} />
            </IconButton>
          );
        }

        return (
          <Tooltip title={<TooltipBar name={"vpnicon"} rowData={rowData} />} placement="top" arrow interactive>
            <VpnKeySharpIcon className={"hovericon"} />
          </Tooltip>
        );
      },
    },
  ];
};
export const RECENTLY_FINISHED_ROUTES_COLUMNS = (tableRef, markFavourite, redirectView, t, sorting, user) => {
  return [
    {
      title: "icon",
      render: (rowData) => (
        <div style={{ display: "flex" }}>
          {!!markFavourite && (
            <StarRateIcon
              onClick={(e) => markFavourite(e, rowData)}
              style={{
                color: rowData.is_favourite ? "#6F9CEB" : "#ADADAD",
                cursor: "pointer",
              }}
            />
          )}
          {!!rowData.code && (
            <MapIcon onClick={() => redirectView(null, rowData)} style={{ color: "#ADADAD", cursor: "pointer" }} />
          )}
        </div>
      ),
      customFilterAndSearch: (v, row) => {
        const regex = new RegExp(v, "gi");

        return regex.test(`t.${row.uuid || ""} ${row.uuid || ""} ${row.Tour?.name || ""}`);
      },
    },
    {
      title: "date",
      render: (rowData) => {
        if (!rowData.start_date) {
          if (!rowData?.created_at) {
            return "-";
          }
        }

        return (
          <>
            <span
              style={{
                font: "normal normal bold 18px/24px Roboto",
                color: "#F5F5F5",
              }}
            >
              {rowData.start_date
                ? moment(rowData.start_date).format("DD.MM.YYYY")
                : moment(rowData.created_at).format("DD.MM.YYYY")}{" "}
              {rowData.start_date ? moment(rowData.start_date).format("HH:mm") : ""}
            </span>
          </>
        );
      },
    },
    {
      title: "tour",
      field: "tour",
      render: (rowData) => <span>T.{rowData.uuid}</span>,
    },
    {
      title: "name",
      render: (rowData) => {
        try {
          const gdo =
            rowData.Tour.name +
            (rowData.pathway[0].Orders[0].departure ? ` (${t(rowData.pathway[0].Orders[0].departure)})` : "");

          return gdo;
        } catch (e) {
          console.log(e);
          return rowData.Tour.name;
        }
      },
    },
    {
      title: "progress",
      render: (rowData) => {
        if (rowData.progress === "Completed") {
          return (
            <span
              style={{
                color: "#6F9CEB",
                font: "normal normal normal 18px/24px Roboto",
              }}
            >
              {t(rowData.progress)}
              <small style={{ display: "block" }}>{moment(rowData.end_date).format("DD.MM.YYYY")}</small>
            </span>
          );
        }

        return (
          <span
            style={{
              font: "normal normal normal 18px/24px Roboto",
              color: "#F5F5F5",
            }}
          >
            {t(rowData.progress)}
          </span>
        );
      },
    },
    {
      title: "noOfOrders",
      render: (rowData) =>
        `${rowData.pathway.filter((p) => p.Orders.every((o) => o.delivered_at)).length} / ${rowData.pathway.length}`,
    },
    {
      title: "DriversName",
      field: "driver_name",
      render: (rowData) => (
        <span
          style={{
            font: "normal normal bold 18px/24px Roboto",
            color: "#F5F5F5",
          }}
        >
          {rowData.driver_name || "-"}
        </span>
      ),
    },
    {
      title: "call",
      render: (rowData) => {
        return (
          <>
            {rowData.driver_name !== null ? (
              <Tooltip title={<TooltipBar name={"callicon"} rowData={rowData} />} placement="top" arrow interactive>
                <CallSharpIcon className={"hovericon"} />
              </Tooltip>
            ) : (
              <CallSharpIcon className={"disabled-btn"} disabled={true} />
            )}
          </>
        );
      },
    },
    {
      title: "key",
      render: (rowData) => {
        if (user?.permissions?.routes?.delivery_app_doc) {
          return (
            <>
              <IconButton
                component="a"
                href={`${process.env.REACT_APP_API}routes/document/${
                  rowData.id
                }/${sorting}?taira=${localStorage.getItem("token")}`}
                target="_blank"
                color="primary"
                style={{ padding: 0 }}
              >
                <DocumentIcon style={{ color: "#6F9CEB" }} />
              </IconButton>
            </>
          );
        }
        if (!rowData.code || user?.permissions?.routes?.hide_key) {
          return (
            <IconButton
              component="a"
              href={`${process.env.REACT_APP_API}routes/document/${rowData.id}/${sorting}?taira=${localStorage.getItem(
                "token"
              )}`}
              target="_blank"
              color="primary"
              style={{ padding: 0 }}
            >
              <DocumentIcon style={{ color: "#6F9CEB" }} />
            </IconButton>
          );
        }

        return (
          <Tooltip title={<TooltipBar name={"vpnicon"} rowData={rowData} />} placement="top" arrow interactive>
            <VpnKeySharpIcon className={"hovericon"} />
          </Tooltip>
        );
      },
    },
    {
      title: "key2",
      render: (rowData) => {
        if (user?.permissions?.routes?.delivery_app_doc) {
          return (
            <Tooltip title={<TooltipBar name={"vpnicon"} rowData={rowData} />} placement="top" arrow interactive>
              <VpnKeySharpIcon className={"hovericon"} />
            </Tooltip>
          );
        }
      },
    },
  ];
};
export const checkPaths = [
  PATHS.tours.root,
  PATHS.tours.add,
  PATHS.tours.edit,
  PATHS.tours.detail,
  PATHS.customers.root,
  PATHS.customers.archive,
  PATHS.customers.add,
  PATHS.customers.edit,
  PATHS.customers.detail,
];
export const checkRoutePaths = [
  PATHS.routes.current,
  PATHS.routes.recent,
  PATHS.routes.archive,
  PATHS.routes.export,
  PATHS.routes.mapRecent,
  PATHS.routes.mapArchive,
];
