import { createSlice, createSelector } from "@reduxjs/toolkit";
import { coreApi } from "api/core";
import { setShowMessage } from "redux/slices/uiSlice";

const baseUrl = "/supplier-locations";
const initialState = {
  supplierLocations: [],
  supplierLocation: {},
  loading: false,
};

const supplierLocationsSlice = createSlice({
  name: "supplierLocations",
  initialState,
  reducers: {
    setSupplierLocation: (state, action) => {
      state.supplierLocation = action.payload;
    },
    setSupplierLocations: (state, action) => {
      state.supplierLocations = action.payload;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setReady: (state) => {
      state.loading = false;
    },
    clearSupplierLocations: (state) => {
      state.supplierLocations = [];
      state.customer = null;
    },
  },
});

export const { setSupplierLocation, setSupplierLocations, setLoading, setReady, clearSupplierLocations } =
  supplierLocationsSlice.actions;
export default supplierLocationsSlice.reducer;

export const getSupplierLocations = () => async (dispatch) => {
  dispatch(setLoading());

  try {
    const supplierLocations = await coreApi.fetch(baseUrl, "GET");
    dispatch(setSupplierLocations(supplierLocations));
  } catch (err) {
    console.error(err);
    dispatch(
      setShowMessage({
        description: "Failed to fetch supplier locations",
        type: "error",
      })
    );
  } finally {
    dispatch(setReady());
  }
};

export const getSupplierLocation = (id) => async (dispatch) => {
  dispatch(setLoading());

  try {
    const url = baseUrl + `/${id}`;
    const supplierLocation = await coreApi.fetch(url, "GET");
    dispatch(setSupplierLocation(supplierLocation));
  } catch (err) {
    console.error(err);
    dispatch(
      setShowMessage({
        description: "Failed to fetch supplier locations",
        type: "error",
      })
    );
  } finally {
    dispatch(setReady());
  }
};

export const addSupplierLocation = (payload) => async (dispatch) => {
  dispatch(setLoading());

  try {
    const res = await coreApi.post(baseUrl, payload);
    dispatch(
      setShowMessage({
        description: "Supplier Location created successfully",
        type: "success",
      })
    );
    return res;
  } catch (err) {
    console.log(err);
    console.dir(err, { depth: null });
    if (err.response.status === 409) {
      dispatch(
        setShowMessage({
          description: "Supplier Location already exists",
          type: "error",
        })
      );
    } else {
      dispatch(
        setShowMessage({
          description: "Failed to create Supplier Location",
          type: "error",
        })
      );
    }
  } finally {
    dispatch(clearSupplierLocations());
    dispatch(setReady());
  }
};
export const editSupplierLocation = (id, payload) => async (dispatch) => {
  dispatch(setLoading());

  try {
    const url = baseUrl + `/${id}`;
    const res = await coreApi.put(url, payload);
    dispatch(
      setShowMessage({
        description: "Supplier Location created successfully",
        type: "success",
      })
    );
    return res;
  } catch (err) {
    console.log(err);
    console.dir(err, { depth: null });
    if (err.response.status === 409) {
      dispatch(
        setShowMessage({
          description: "Supplier Location already exists",
          type: "error",
        })
      );
    } else {
      dispatch(
        setShowMessage({
          description: "Failed to create Supplier Location",
          type: "error",
        })
      );
    }
  } finally {
    dispatch(clearSupplierLocations());
    dispatch(setReady());
  }
};

const supplierLocationsSelector = ({ supplierLocations }) => supplierLocations?.supplierLocations || [];
const supplierLocationSelector = ({ supplierLocations }) => supplierLocations?.supplierLocation || {};
const supplierLocationsStatusSelector = ({ supplierLocations }) => supplierLocations.loading;

export const selectSupplierLocations = createSelector(
  supplierLocationsSelector,
  (supplierLocations) => supplierLocations
);
export const selectSupplierLocation = createSelector(supplierLocationSelector, (supplierLocation) => supplierLocation);
export const selectSupplierLocationsStatus = createSelector(supplierLocationsStatusSelector, (loading) => loading);
