import { createSlice, createSelector } from "@reduxjs/toolkit";
import { coreApi } from "api/core";
import { setShowMessage } from "redux/slices/uiSlice";

const baseUrl = "/supplier-groups";
const initialState = {
  supplierGroups: [],
  supplierGroup: {},
  loading: false,
};

const supplierGroupsSlice = createSlice({
  name: "supplierGroups",
  initialState,
  reducers: {
    setSupplierGroup: (state, action) => {
      state.supplierGroup = action.payload;
    },
    setSupplierGroups: (state, action) => {
      state.supplierGroups = action.payload;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setReady: (state) => {
      state.loading = false;
    },
    clearSupplierGroups: (state) => {
      state.supplierGroups = [];
      state.supplierGroup = null;
    },
  },
});

export const { setSupplierGroup, setSupplierGroups, setLoading, setReady, clearSupplierGroups } =
  supplierGroupsSlice.actions;
export default supplierGroupsSlice.reducer;

// Fetch all supplier groups
export const getSupplierGroups = () => async (dispatch) => {
  dispatch(setLoading());

  try {
    const supplierGroups = await coreApi.fetch(baseUrl, "GET");
    dispatch(setSupplierGroups(supplierGroups));
  } catch (err) {
    console.error(err);
    dispatch(
      setShowMessage({
        description: "Failed to fetch supplier groups",
        type: "error",
      })
    );
  } finally {
    dispatch(setReady());
  }
};

export const getSupplierGroup = () => async (dispatch) => {
  dispatch(setLoading());

  try {
    const supplierGroup = await coreApi.fetch(baseUrl, "GET");
    dispatch(setSupplierGroup(supplierGroup));
  } catch (err) {
    console.error(err);
    dispatch(
      setShowMessage({
        description: "Failed to fetch supplier group",
        type: "error",
      })
    );
  } finally {
    dispatch(setReady());
  }
};

// Update a supplier group
export const updateSupplierGroup = (id, payload) => async (dispatch) => {
  dispatch(setLoading());

  try {
    const url = baseUrl + `/${id}`;
    const res = await coreApi.put(url, payload);
    dispatch(
      setShowMessage({
        description: "Supplier group updated successfully",
        type: "success",
      })
    );
    return res;
  } catch (err) {
    console.log(err);
    if (err.response?.status === 409) {
      dispatch(
        setShowMessage({
          description: "Supplier group already exists",
          type: "error",
        })
      );
    } else {
      dispatch(
        setShowMessage({
          description: "Failed to update supplier group",
          type: "error",
        })
      );
    }
  } finally {
    dispatch(setReady());
  }
};

// Selectors
const supplierGroupsSelector = ({ supplierGroups }) => supplierGroups?.supplierGroups || [];
const supplierGroupSelector = ({ supplierGroups }) => supplierGroups?.supplierGroup || {};
const supplierGroupsStatusSelector = ({ supplierGroups }) => supplierGroups.loading;

export const selectSupplierGroups = createSelector(supplierGroupsSelector, (supplierGroups) => supplierGroups);
export const selectSupplierGroup = createSelector(supplierGroupSelector, (supplierGroup) => supplierGroup);
export const selectSupplierGroupsStatus = createSelector(supplierGroupsStatusSelector, (loading) => loading);
